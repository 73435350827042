import React from 'react';
import { motion } from 'framer-motion';

interface SpinnerProps {
    size?: number;       // Customize spinner size
    speed?: number;       // Customize rotation speed
}

const Spinner: React.FC<SpinnerProps> = ({ size = 32, speed = 1 }) => {
    return (
        <motion.div
            animate={{ rotate: 360 }}
            transition={{ repeat: Infinity, duration: speed, ease: "linear" }}
            style={{
                width: size,
                height: size,
                borderTopColor: 'transparent',
                borderRadius: '50%',
            }}
            className="border-4 border-focus-lg dk:border-border-dk"
            role="status"
        >
            <span className="sr-only">Loading...</span>
        </motion.div>
    );
};

export default Spinner;