import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import { UUID } from 'crypto';

// hooks
import useChargepointDetails from '../hooks/useChargepointDetails';
import useStartStopSession from '../hooks/useStartStopSession';

// components
import Heading from '../components/typography/Heading';
import LeafletMap from '../components/Map';
import Timeline from '../components/Timeline';
import CardGrid, { CardItem, CardType } from '../components/positioning/CardGrid';
import Card from '../components/basics/Card';
import Button, { ButtonVariant } from '../components/buttons/Button';
import ErrorPage from './ErrorPage';


const ChargepointDetailsPage: React.FC = () => {
    const { id } = useParams<{ id: UUID }>();
    const { chargepointDetails, error, loading, refetch } = useChargepointDetails(id!); // maybe give specific name here too
    const { t } = useTranslation();
    const { startStopSession, error: buttonError } = useStartStopSession(id!); // loading not used here

    // change to better error page
    if (error) {
        return <ErrorPage errorCode={500} errorMessage={error} />;
    }

    const chargepoint = chargepointDetails?.chargepoint;

    const handleStartStopSession = async () => {
        if (!chargepoint) return;
        const action = chargepoint.status !== 'CHARGING' ? 'start' : 'stop';
        await startStopSession(action);
        await refetch(); // refetch chargpoint data after update
    };

    const buttonText = chargepoint?.status !== 'CHARGING' ? t('Start Session') : t('Stop Session');
    const variant = chargepoint?.status !== 'CHARGING' ? ButtonVariant.Default : ButtonVariant.Danger;

    const locations = chargepoint ? [
        {
            id: 1,
            chargepoint_uuid: chargepoint.uuid ?? "",
            chargepoint_name: chargepoint.desc ?? "",
            evse_id: chargepoint.evse_id ?? "",
            address: chargepoint.address ?? "",
            position: [chargepoint.loc[1], chargepoint.loc[0]] as [number, number]
        }
    ] : [];

    const center: [number, number] = [
        chargepoint?.loc[1] ?? 48.1351,
        chargepoint?.loc[0] ?? 11.5820
    ];

    // ToDo: add icons for the cards
    const attributeCards: CardItem[] = [
        {
            type: CardType.Attribute,
            value: `${chargepoint?.status !== 'CHARGING' ? t('Available') : t('Unavailable')}`,
            icon: <FaCheckCircle className={`text-lg ${chargepoint?.available ? 'text-enerando-green' : 'text-danger-base'}`} />,
            name: t('status'),
            loading: loading,
        },
        // {
        //     type: CardType.Attribute,
        //     value: `${chargepoint?.tenant.name}`, // missing
        //     name: t('tenant'),
        //     loading: loading,
        // },
        {
            type: CardType.Attribute,
            value: `${chargepoint?.address}`,
            name: t('address'),
            loading: loading,
        },
    ];


    return (
        <div>
            <Heading
                // the title is currently the chargepoint description. Does that make sense?
                title={t(chargepoint?.desc ?? '')}
                highlight={t('chargepoint')}
                description={t('')}
            />
            <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
                <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-8 min-h-full">
                    <div className="w-full md:w-1/2 flex-1">
                        <CardGrid items={attributeCards} loading={loading} />
                        <div className="mt-10">
                            <Button
                                text={buttonText}
                                onClick={handleStartStopSession} 
                                variant={variant}
                                className="w-full"
                            />
                            {buttonError && <div className="text-danger-base mt-2">{buttonError}</div>}
                        </div>
                        <div className="mt-10">
                            <Card
                                title={t('details')}
                                fullWidth={true}
                            >
                                <ul className="list-disc list-inside">
                                    {chargepoint?.public ? (
                                        <li>{t('Public')}</li>
                                    ) : (
                                        <li>{t('Private')}</li>
                                    )}
                                    {chargepoint?.cable ? (
                                        <li>{t('Cable')}</li>
                                    ) : (
                                        <li>{t('No Cable')}</li>
                                    )}
                                    {(chargepoint?.ampsmin !== undefined && chargepoint.amps !== undefined) && (
                                        <li>{t('Current')}: {chargepoint.ampsmin} - {chargepoint.amps} {t('A')}</li>
                                    )}
                                    {chargepoint?.phases !== undefined && (
                                        <li>{t('Phases')}: {chargepoint.phases}</li>
                                    )}
                                </ul>
                            </Card>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 flex-1" >
                        {chargepointDetails?.chargings && (
                            <Card
                                title={t('Passed Sessions')}
                                fullWidth={true}
                            >
                                <div className="max-h-full flex-grow overflow-x-auto" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                    <Timeline sessions={chargepointDetails.chargings} />
                                </div>
                            </Card>
                        )}
                    </div>
                </div>
                {/* Add a search bar */}
                {/* Update to a card which is scrollable */}
                <div className="mt-10">
                    <LeafletMap locations={locations} center={center} loading={loading} />
                </div>

            </div>
        </div>
    );
};

export default ChargepointDetailsPage;
