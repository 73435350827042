import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { BASE_URL } from './apiService';
import { TokenService } from './tokenService';

export interface Mandant {
  name: string;
  slug: string;
  color: string;
}

export interface User {
  // username: string;
  email: string;
  // first_name: string;
  // last_name: string;
  // uuid: string;
  // prio: boolean;
  // adminlevel: string;
  // appuser: boolean;
  // mandant: Mandant | null;
  permissions: string[];
}

interface LoginResponse {
  access: string;
  refresh: string;
}

interface DecodedToken {
  // username: string;
  email: string;
  // first_name: string;
  // last_name: string;
  // uuid: string;
  // prio: boolean;
  // adminlevel: string;
  // appuser: boolean;
  // mandant: Mandant | null;
  permissions: string[];
  exp: number;
}


const apiClient = axios.create({
  baseURL: `${BASE_URL}/api/v2`,
});

const refreshToken = async (): Promise<boolean> => {
  try {
    const refresh = TokenService.getRefreshToken();
    if (!refresh) {
      throw new Error('No refresh token available');
    }
    const response = await apiClient.post<LoginResponse>('/token/refresh', { refresh });
    TokenService.setTokens(response.data.access, response.data.refresh);
    return true;
  } catch (error) {
    console.error('Error refreshing token:', error);
    TokenService.removeTokens();
    return false;
  }
};

const login = async (username: string, password: string): Promise<boolean> => {
  try {
    const response = await apiClient.post<LoginResponse>('/token', { username, password });
    TokenService.setTokens(response.data.access, response.data.refresh);
    return true;
  } catch (error) {
    console.error('Error during authentication:', error);
    return false;
  }
};

const decodeToken = (token: string): DecodedToken | null => {
  try {
    return jwtDecode<DecodedToken>(token);
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

const getUser = async (): Promise<User | null> => {
  let token = TokenService.getAccessToken();
  if (!token) return null;

  let decodedToken = decodeToken(token);
  if (!decodedToken || decodedToken.exp * 1000 < Date.now()) {
    const refreshed = await refreshToken();
    if (!refreshed) return null;

    token = TokenService.getAccessToken();
    decodedToken = decodeToken(token!);
  }

  if (decodedToken) {
    const { email, permissions } = decodedToken;
    return { email, permissions };
  }

  return null;
};

const logout = () => {
  TokenService.removeTokens();
  // here maybe invalidate the refresh token server-side
};

export { login, logout, getUser };
