import React from 'react';
import { motion } from 'framer-motion';

interface HeadingProps {
    title: string;
    highlight: string;
    description: string;
}

const Heading: React.FC<HeadingProps> = ({ title, highlight, description }) => {
    return (
        <div>
            {/* Animate the container to fade and slide in */}
            <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
                className="mb-4 text-3xl font-heading font-normal text-txt-lg-header dark:text-txt-dk-header md:text-5xl lg:text-6xl uppercase"
            >
                {/* Animated highlight with a pulsing effect */}
                <motion.span
                    initial={{ scale: 1 }}
                    animate={{ scale: [1, 1.1, 1] }}
                    transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
                    className="text-enerando-green"
                >
                    {highlight}
                </motion.span>
                {' '}{title}
            </motion.h1>
            {/* Animate description with a slight delay */}
            <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
                className="text-lg font-body font-normal text-txt-lg-body lg:text-xl dark:text-txt-dk-body"
            >
                {description}
            </motion.p>
        </div>
    );
};

export default Heading;
