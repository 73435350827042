import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { IoCarSportSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import Button, { ButtonVariant } from './buttons/Button';
import useStartStopSession from '../hooks/useStartStopSession';
import { useParams } from 'react-router-dom';


interface StatusIndicatorProps {
    status: string;
    loading: boolean;
}

// Function to determine the outline class and icon color based on state
const getStatusStyles = (status: string) => {
    switch (status) {
        case 'ACTIVE':
            return {
                outlineClass: 'pulsating-outline',
                iconColor: 'text-charging-start',
                labelText: 'Charging',
            };
        case 'COMPLETED':
            return {
                outlineClass: 'static-outline',
                iconColor: 'text-enerando-green',
                labelText: 'Done',
            };
        case 'PENDING':
            return {
                outlineClass: 'static-outline',
                iconColor: 'text-enerando-blue',
                labelText: 'Pending',
            };
        // add for error state
        default:
            return {
                outlineClass: 'static-outline',
                iconColor: 'text-enerando-gray',
                labelText: 'Invalid',
            };
    }
};

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status, loading }) => {
    const { t } = useTranslation();
    // const { id } = useParams<{ id: UUID }>(); // This is the session uuid, find cp
    // const { startStopSession, error: buttonError } = useStartStopSession(id!); // loading not used here

    const buttonText = t('Stop Session');
    const variant = ButtonVariant.Danger;

    // change to better error page
    // if (error) {
    //     return <ErrorPage errorCode={500} errorMessage={error} />;
    // }

    if (loading) {
        return (
            <div className="flex flex-col items-center mb-6">
                <Skeleton height={112} width={112} style={{ borderRadius: '1.5rem' }} />
                <Skeleton width={100} height={24} className="mt-2" />
            </div>
        );
    }

    const { outlineClass, iconColor, labelText } = getStatusStyles(status);

    const handleStartStopSession = async () => {
        const action = 'stop';
        // await startStopSession(action);
        // await refetch(); // refetch chargpoint data after update
    };

    return (
        <div className="flex flex-col items-center mb-6">
            <div className={`relative flex items-center justify-center w-28 h-28 rounded-full ${outlineClass}`}>
                {/* Car icon with dynamic color */}
                <IoCarSportSharp className={`z-20 text-6xl ${iconColor}`} />
            </div>
            {/* State label with dynamic color */}
            <p className="mt-2 text-lg font-body font-normal text-sm text-center capitalize">
                {t(labelText)}
            </p>
            {(status === 'ACTIVE') && (
                <div className="mt-10">
                    <Button
                        text={buttonText}
                        onClick={handleStartStopSession} 
                        variant={variant}
                        className="w-full"
                    />
                    {/* {buttonError && <div className="text-danger-base mt-2">{buttonError}</div>} */}
                </div>
            )}
        </div>
    );
};

export default StatusIndicator;
