import React from 'react';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Fix for default marker icon issue
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import Card from '../components/basics/Card';


L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow
});

interface Location {
    id: number;
    chargepoint_uuid: string;
    chargepoint_name: string;
    evse_id: string;
    address: string;
    position: [number, number];
}

interface MyMapProps {
    center: [number, number];
    locations: Location[];
    loading: boolean;
}

const LeafletMap: React.FC<MyMapProps> = ({ locations, center, loading }) => {
    return (
        <div className="map-container rounded-2xl shadow-sm overflow-hidden border border-border-lg dark:border-border-dk">		 
            <Card
                title={`Charging Station: ${locations[0]?.chargepoint_name ?? "Unknown"}`}
                description={`EVSE ID: ${locations[0]?.evse_id ?? "N/A"}`}
                loading={loading}
                fullWidth={true}
                href={`/infrastructure/${locations[0]?.chargepoint_uuid}`}
                >
            {loading ? (
                <Skeleton height={400} width="100%" />
            ) : (
            
                <MapContainer center={center} zoom={15} style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {locations.map((location) => (
                        <Marker key={location.id} position={location.position}>
                            <Tooltip direction="bottom">
                                    {location.address} 
                            </Tooltip>
                        </Marker>
                    ))}
                </MapContainer>
            )}
            </Card>
        </div>
    );
};

export default LeafletMap;
