import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UUID } from "crypto";
import { useNavigate } from 'react-router-dom';

import useUserDetails from "../hooks/useUserDetails";

import Heading from '../components/typography/Heading';
import Table, { TableColumn, TableRow } from '../components/basics/table';
import CardGrid, { CardItem, CardType } from "../components/positioning/CardGrid";
import SessionsTable from "../components/SessionsTable";
// icons
import { FaRegCircleUser } from "react-icons/fa6";
import ErrorPage from "./ErrorPage";
import { FaCheckCircle } from "react-icons/fa";
import { HiOfficeBuilding } from "react-icons/hi";
import { IoIosCloseCircle } from "react-icons/io";
import { MdDateRange, MdAlternateEmail } from "react-icons/md"; // created


const UserDetailPage: React.FC = () => {
    const { id } = useParams<{ id: UUID }>();
    const { userDetails, cards, sessions, error, loading } = useUserDetails(id!);
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (error) {
        return <ErrorPage errorCode={500} errorMessage={error} />;
    }

    const user = userDetails;
    const cardlist = cards ? cards : [];

    const cardColumns: TableColumn[] = [
        // adjust header
        { header: t("card numbers"), key: "number" },
        { header: t("status"), key: "enabled" },
    ]

    const cardRows: TableRow[] = cardlist.map(card => ({
        number: card.number,
        enabled: card.enabled ? "Active" : "Inactive",
        uuid: card.uuid ?? "",
    }));

    const userCards: CardItem[] = [
        {
            type: CardType.Attribute,
            name: t('name'),
            value: user?.name || "N/A",
            loading: loading,
            icon: <FaRegCircleUser className={`text-lg text-enerando-green`} />,
        },
        {
            type: CardType.Attribute,
            name: t('email'),
            value: user?.email,
            loading: loading,
            icon: <MdAlternateEmail className={`text-lg text-enerando-green`} />,
        },
        {
            type: CardType.Attribute,
            name: t('status'),
            value: `${user?.is_active ? t('Active') : t('Inactive')}`,
            loading: loading,
            icon: <FaCheckCircle className={`text-lg ${user?.is_active ? 'text-enerando-green' : 'text-danger-base'}`} />,
        },
        {
            type: CardType.Attribute,
            name: t('company'),
            value: user?.company || "N/A",
            loading: loading,
            icon: <HiOfficeBuilding className={`text-lg text-enerando-green`} />,
        },
        {
            type: CardType.Attribute,
            name: t('priority'),
            value: (user?.prio) ? t("Yes") : t("No"),
            loading: loading,
            icon: user?.prio ? <FaCheckCircle className={`text-lg text-enerando-green`} /> : <IoIosCloseCircle className={`text-lg text-enerando-red`} />,
        },
        {
            type: CardType.Attribute,
            name: t('created'),
            value: user?.date_joined ? new Date(user?.date_joined).toLocaleString() : 'N/A',
            loading: loading,
            icon: <MdDateRange className={`text-lg text-enerando-green`} />,
        },
    ];

    return (
        <div>
            <Heading
                title={t('Details')}
                highlight={t('User')}
                description={t('All details about this user.')}
            />
            <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
                <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-8 min-h-full">
                    <div className="w-full md:w-1/2 flex flex-col space-y-4 flex-1">
                        <CardGrid items={userCards} loading={loading} />
                    </div>
                    <div className="w-full md:w-1/2 flex-1">
                        <Table 
                            table_name={"cards_" + user?.email} 
                            columns={cardColumns} 
                            data={cardRows} 
                            onRowClick={(row) => navigate(`/cards/${row.uuid}`)}
                            loading={loading} 
                        />
                    </div>
                </div>
                <div className="mt-20">
                    <SessionsTable 
                        availableViews={["today", "yesterday", "month", "year"]} 
                        defaultView="today"
                        userId={id}
                        showHeader
                    />  
                </div>
            </div>
        </div>
    )

};

export default UserDetailPage;