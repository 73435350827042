import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Hooks & utils
import useChargingDetails from '../hooks/useSessionDetails';
import { calculateDuration } from '../utils/utils';

// Components
import Heading from '../components/typography/Heading';
import CardGrid, { CardItem, Alignment, CardType } from '../components/positioning/CardGrid';
import StatusIndicator from '../components/StatusIndicator';
import SessionTime from '../components/SessionTime';
import LeafletMap from '../components/Map';
import Card from '../components/basics/Card';
import CollapsedContent from '../components/CollapsedContent';
import XmlExportButton from '../components/buttons/XmlExportButton';
import PdfExportButton from '../components/buttons/PdfExportButton';
import Receipt from '../components/Receipt';
import Spinner from '../components/Spinner';
import ErrorPage from './ErrorPage';

// Icons
import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaMoneyBills, FaLocationDot, FaRegCircleUser, FaCreditCard } from "react-icons/fa6";
import { IoTimer } from "react-icons/io5";
import { UUID } from 'crypto';

const SessionDetailsPage: React.FC = () => {
    const { id } = useParams<{ id: UUID }>();
    const { sessionDetails, cdrDetails, tenantDetails, error, loading } = useChargingDetails(id!);
    const [duration, setDuration] = useState<string>("00:00:00");
    const { t } = useTranslation();

    useEffect(() => {
        if (sessionDetails && sessionDetails.start) {
            const startTime = sessionDetails.start;
            let stopTime = sessionDetails.state === 'charging' ? new Date().toISOString() : sessionDetails.completed_at || new Date().toISOString();

            setDuration(calculateDuration(startTime, stopTime));

            if (sessionDetails.state === 'charging') {
                const intervalId = setInterval(() => {
                    stopTime = new Date().toISOString();
                    setDuration(calculateDuration(startTime, stopTime));
                }, 1000);

                return () => clearInterval(intervalId);
            }
        }
    }, [sessionDetails]);

    if (error) {
        return <ErrorPage errorCode={500} errorMessage={error} />;
    }

    // Get this info from cdrDetails if it exists
    const energy = sessionDetails?.energy ?? 0;
    const user_id = sessionDetails?.user_uuid ?? '';
    const user_name = sessionDetails?.user_name ?? '';
    const status = sessionDetails?.status ?? '';

    const token = sessionDetails?.token_uid ?? '';
    const badge_id = sessionDetails?.badge_id ?? '';
    const tariff_name = tenantDetails?.tariff_name ?? '';
    
    const tariff = cdrDetails?.cdr_energy_tariff ?? cdrDetails?.cdr_time_tariff ?? 0;
    const net = cdrDetails?.cdr_total_excl_vat?? 0;
    const vatRate = cdrDetails?.cdr_vat_rate?? 0;
    const vat = cdrDetails?.cdr_total_vat?? 0;
    const totalCost = cdrDetails?.cdr_total_incl_vat ?? 0;

    const tenant_name = tenantDetails?.name;
    const address = tenantDetails?.address;
    const plz = tenantDetails?.plz;
    const city = tenantDetails?.city;
    const state = tenantDetails?.state;
    const country = tenantDetails?.country;
    const vat_id = tenantDetails?.vat_id;
    const authorization = tenantDetails?.authorization ?? '';

    const start_fee = 0; //TODO: Get from db when available, will be used for GLS and QR Code
    
    // Safely handle potential null or undefined values
    const locations = sessionDetails && sessionDetails.loc && sessionDetails.address && sessionDetails.chargepoint_name ? [
        {
            id: 1,
            chargepoint_uuid: sessionDetails.chargepoint,
            chargepoint_name: sessionDetails.chargepoint_name,
            evse_id: sessionDetails.evse_id,
            address: sessionDetails.address,
            position: [sessionDetails.loc[1], sessionDetails.loc[0]] as [number, number]
        }
    ] : [];

    const center: [number, number] = sessionDetails && sessionDetails.loc ? [
        sessionDetails.loc[1] ?? 48.1351,
        sessionDetails.loc[0] ?? 11.5820
    ] : [48.1351, 11.5820];

    const attributeItems = [
        {
            type: CardType.Default,
            fullWidth: true,
            alignment: Alignment.Center,
            children: (
                <SessionTime
                    startTime={sessionDetails?.start ? new Date(sessionDetails.start) : undefined}
                    endTime={sessionDetails?.completed_at ? new Date(sessionDetails.completed_at) : undefined}
                    status={sessionDetails?.status ?? ''}
                    loading={loading}
                />
            ),
            loading: loading
        },
        {
            type: CardType.Default,
            fullWidth: true,
            alignment: Alignment.Center,
            children: (
                <StatusIndicator
                    status={sessionDetails?.status ?? ''}
                    loading={loading}
                />
            ),
            loading: loading
        },
        {
            type: CardType.Attribute,
            value: sessionDetails?.address || t('No address available'),
            name: t('address'),
            icon: <FaLocationDot className="mb-2 text-2xl text-enerando-green" />,
            loading: loading,
        },
        {
            type: CardType.Attribute,
            value: sessionDetails?.energy != null ? sessionDetails.energy.toString() : t('N/A'),
            name: `${t('charged')} ${t('energy')} (kWh)`,
            icon: <BsFillLightningChargeFill className="w-5 h-5 text-enerando-green" />,
            loading: loading,
        },
        {
            type: CardType.Attribute,
            value: duration,
            name: t('duration'),
            icon: <IoTimer className="w-5 h-5 text-enerando-green" />,
            loading: loading,
        },
        user_name ? { //Not visible for QR and Token
            type: CardType.Attribute,
            value: `${user_name}`,
            name: t('user'),
            icon: <FaRegCircleUser className="w-5 h-5 text-enerando-green" />,
            loading: loading,
        } : null,
        badge_id ? { //Not visible for QR and Token
            type: CardType.Attribute,
            value: badge_id,
            name: t('card'),
            icon: <FaCreditCard className="w-5 h-5 text-enerando-green" />,
            loading: loading,
        } : null,
        token  ? { //Not visible for QR and RFID
            type: CardType.Attribute,
            value: token,
            name: t('token'),
            icon: <FaCreditCard className="w-5 h-5 text-enerando-green" />,
            loading: loading,
        } : null,
        {
            type: CardType.Attribute,
            value: tariff_name,
            name: t('tariff'),
            icon: <FaMoneyBills className="w-5 h-5 text-enerando-green" />,
            loading: loading,
        },
        {
            type: CardType.Attribute,
            value: `${tariff.toFixed(2)} € / kwh`,
            name: t('price (excl. VAT)'),
            icon: <FaMoneyBills className="w-5 h-5 text-enerando-green" />,
            loading: loading,
        },
        {
            type: CardType.Attribute,
            value: `${start_fee} € / session`,
            name: t('start fee (excl. VAT)'),
            icon: <FaMoneyBills className="w-5 h-5 text-enerando-green" />,
            loading: loading,
        },
        {
            type: CardType.Attribute,
            value: `${totalCost.toFixed(2)} €`,
            name: t('session total (incl. VAT)'),
            icon: <FaMoneyBills className="w-5 h-5 text-enerando-green" />,
            loading: loading,
        },
    ].filter(Boolean) as CardItem[];

    const signedData = cdrDetails?.cdr_signed_value || null;
    const publicKey = cdrDetails?.cdr_public_key || null;

    const receiptData = {
        transactionId: sessionDetails?.uuid ?? '',
        tenant_name,
        address,
        plz,
        city,
        state,
        country,
        vat_id,
        period: `${sessionDetails?.start ?? ''} - ${sessionDetails?.completed_at ?? ''}`,
        energy,
        time: duration,
        tariff,
        net,
        vat,
        vatRate,
        totalCost,
        user_id,
        token,
        authorization,
        start_fee,
    }
    
    // Extend `receiptData` while adding `evse_id`
    const invoiceData = {
        ...receiptData,
        evse_id: sessionDetails?.evse_id ?? "N/A",
    };


    return (
        <div>
            <Heading
                title={t('session')}
                highlight={t('charging')}
                description=""
            />

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <Spinner size={32} />
                </div>

            ) : (
                <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
                    <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-8 min-h-full">
                        <div className="w-full md:w-1/2 flex flex-col space-y-4 flex-1">
                            <CardGrid items={attributeItems} loading={loading} />
                        </div>
                        <div className="w-full md:w-1/2 flex-1">
                            <LeafletMap locations={locations} center={center} loading={loading} />
                        </div>
                    </div>

                    
                        <div className="mt-10 flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-8 min-h-full">
                            {/* Only show the receipt and transparency data cards if the session is done */}
                            {(status === 'COMPLETED' || status === 'INVALID') && (
                                <>
                                    <div className="w-full md:w-1/2 flex flex-col space-y-4 flex-1">
                                        <Card
                                            title={t('Receipt')}
                                            loading={loading}
                                            fullWidth={true}
                                            exportButton={
                                                <PdfExportButton
                                                    filename={`receipt_${receiptData.transactionId}.pdf`}
                                                    data={invoiceData}
                                                />
                                            }
                                        >
                                            <Receipt
                                                transactionId={receiptData.transactionId}
                                                name={receiptData.tenant_name}
                                                address={receiptData.address}
                                                plz={receiptData.plz}
                                                city={receiptData.city}
                                                state={receiptData.state}
                                                country={receiptData.country}
                                                vat_id={receiptData.vat_id}
                                                period={receiptData.period}
                                                energy={receiptData.energy}
                                                time={receiptData.time}
                                                tariff={receiptData.tariff}
                                                net={receiptData.net}
                                                vat={receiptData.vat}
                                                vatRate={receiptData.vatRate}
                                                totalCost={receiptData.totalCost}
                                                user={receiptData.user_id}
                                                token={receiptData.token}
                                                authorization={receiptData.authorization}
                                                start_fee={receiptData.start_fee}
                                            />
                                        </Card>
                                    </div>
                                    <div className="w-full md:w-1/2 flex flex-col space-y-4 flex-1">
                                        {(publicKey || signedData) && (
                                            <Card
                                                title={t('Transparency Data')}
                                                loading={loading}
                                                fullWidth={true}
                                                exportButton={
                                                    id && signedData && publicKey && (
                                                        <XmlExportButton
                                                            filename={`Enerando_session_${id}.xml`}
                                                            data={{
                                                                transactionId: id,
                                                                cdrSignedValue: signedData,
                                                                cdrPublicKey: publicKey
                                                            }}
                                                        />
                                                    )
                                                }
                                            >
                                                {signedData && (
                                                    <CollapsedContent title={t('Signed Meter Data')}>
                                                        <code className="font-mono text-xs leading-tight break-all">
                                                            { signedData }
                                                        </code>
                                                        {/* <ThisJSONTree key={darkMode ? 'dark' : 'light'} data={signedValJson} /> */}
                                                    </CollapsedContent>
                                                )}
                                                {publicKey && (
                                                <CollapsedContent title={t('Public Key')}>
                                                    <code className="font-mono text-xs leading-tight break-all">
                                                        { publicKey }
                                                    </code>
                                                </CollapsedContent>
                                                )}
                                            </Card>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                </div>
            )}
        </div>
    );
};

export default SessionDetailsPage;
