import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PiBatteryVerticalEmptyBold, PiFileCsvBold } from 'react-icons/pi';
import { motion, AnimatePresence } from 'framer-motion';
import Spinner from '../Spinner';

export interface TableColumn {
    header: string;
    key: string;
    isEditable?: boolean;
}

export interface TableRow {
    [key: string]: string | number;
}

interface TableProps {
    table_name: string;
    columns: TableColumn[];
    data: TableRow[];
    onRowClick?: (row: TableRow) => void;
    loading: boolean;
    loadingMore?: boolean;
    isScrollable?: boolean;
    onLoadMore?: () => void;
    offsetTop?: number;
}

const Table: React.FC<TableProps> = ({
    table_name,
    columns,
    data,
    onRowClick,
    loading,
    loadingMore = false,
    isScrollable = false,
    onLoadMore,
    offsetTop = 0,
}) => {
    const { t } = useTranslation();
    const tableWrapperRef = useRef<HTMLDivElement>(null);
    const [availableHeight, setAvailableHeight] = useState<number>(0);

    useEffect(() => {
        const calculateHeight = () => {
            if (typeof window !== 'undefined') {
                setAvailableHeight(window.innerHeight - offsetTop);
            }
        };

        window.addEventListener('resize', calculateHeight);
        calculateHeight();

        return () => {
            window.removeEventListener('resize', calculateHeight);
        };
    }, [offsetTop]);

    const handleScroll = () => {
        if (!isScrollable || !tableWrapperRef.current || loading || loadingMore || !onLoadMore) return;
        const { scrollTop, scrollHeight, clientHeight } = tableWrapperRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
            onLoadMore();
        }
    };

    useEffect(() => {
        if (isScrollable) {
            const tableWrapper = tableWrapperRef.current;
            tableWrapper?.addEventListener('scroll', handleScroll);
            return () => tableWrapper?.removeEventListener('scroll', handleScroll);
        }
    }, [isScrollable, loading, loadingMore]);

    // Function to export data as CSV
    const handleExportCSV = () => {
        const csvContent = `data:text/csv;charset=utf-8,${[
            columns.map(col => col.header).join(','),
            ...data.map(row => columns.map(col => row[col.key]).join(','))
        ].join('\n')}`;

        const now = new Date();
        const timestamp = now.toISOString().replace(/T/, '_').replace(/:/g, '-').split('.')[0];
        const fileName = `${table_name}_${timestamp}.csv`;

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const rowVariants = {
        hidden: { opacity: 0.5, y: 10 },
        visible: (index: number) => ({
            opacity: 1,
            y: 0,
            transition: { delay: index * 0.05, duration: 0.3, ease: 'easeOut' },
        }),
    };

    return (
        <motion.div
            className="relative rounded-2xl overflow-hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
        >
            <div
                ref={tableWrapperRef}
                className="w-full overflow-x-auto"
                style={{ maxHeight: availableHeight > 0 ? `${availableHeight}px` : undefined, overflowY: 'auto' }}
            >
                <table className="font-body font-normal w-full text-sm text-left rtl:text-right text-txt-lg-subtitle dark:text-txt-dk-subtitle table-auto border-separate border-spacing-0">
                    <thead
                        className="font-body font-normal text-xs text-white uppercase bg-enerando-blue dark:bg-bg-dk-input dark:text-txt-dk-subtitle"
                        style={{ position: 'sticky', top: 0, zIndex: 1 }}
                    >
                        <tr>
                            {columns.map((column, index) => (
                                <th
                                    key={column.key}
                                    scope="col"
                                    className={`px-6 py-3 bg-enerando-blue dark:bg-bg-dk-input ${index === 0 ? 'rounded-tl-2xl' : ''}`}
                                >
                                    {column.header}
                                </th>
                            ))}
                            <th className="px-6 py-3 text-right bg-enerando-blue dark:bg-bg-dk-input rounded-tr-2xl">
                                <button onClick={handleExportCSV} title={t('Export to CSV')}>
                                    <PiFileCsvBold className="text-xl text-white" />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <AnimatePresence>
                            {!loading && data.length > 0 && (
                                data.map((row, rowIndex) => (
                                    <motion.tr
                                        key={`data-${row.uuid || rowIndex}`}
                                        className="font-body font-normal bg-white border-b border-border-lg dark:bg-bg-dk-content hover:bg-hover-lg dark:hover:bg-hover-dk"
                                        onClick={() => onRowClick && onRowClick(row)}
                                        style={{ cursor: onRowClick ? 'pointer' : 'default' }}
                                        initial="hidden"
                                        animate="visible"
                                        exit="hidden"
                                        custom={rowIndex}
                                        variants={rowVariants}
                                    >
                                        {columns.map((column) => (
                                            <td
                                                key={column.key}
                                                className="font-body px-6 py-4 font-normal text-txt-lg-body dark:text-txt-dk-body break-words"
                                            >
                                                {row[column.key]}
                                            </td>
                                        ))}
                                        <td></td>
                                    </motion.tr>
                                ))
                            )}
                            {(loadingMore || loading) && (
                                <div className="overlay">
                                    <Spinner size={64} />
                                </div>
                            )}
                        </AnimatePresence>

                        {/* Display "No data found" message if there is no data */}
                        {!loading && data.length === 0 && (
                            <tr>
                                <td colSpan={columns.length + 1} className="px-6 py-4 bg-white dark:bg-bg-dk-content">
                                    <div className="flex flex-col items-center justify-center py-10 bg-white dark:bg-bg-dk-content">
                                        <PiBatteryVerticalEmptyBold className="text-6xl text-enerando-red mb-4" />
                                        <p className="text-lg text-enerando-gray">{t('nothing found ...')}</p>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </motion.div>
    );
};

export default Table;
