import axios from 'axios';
import { UUID } from 'crypto';
import { Paths } from '../api/client';

// export const BASE_URL = process.env.REACT_APP_BASE_URL 
// export const BASE_URL = 'https://api.staging.enerando.systems' //Revisit this

export const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://api.staging.enerando.systems';

if (!BASE_URL) {
  throw new Error('REACT_APP_BASE_URL is not defined. Please set it in your environment variables.');
}

const API_V2 = `${BASE_URL}/api/v2`

const getToken = (): string | null => {
  return localStorage.getItem('accessToken');
};

const axiosInstance = axios.create({
  baseURL: API_V2, // use only new api
});

axiosInstance.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const getChargepoints = async (): Promise<Paths.SitesChargepointsList.Responses.$200> => {
  const response = await axiosInstance.get('/sites/chargepoints');
  return response.data;
};

export const getChargepointDetails = async (id: UUID): Promise<Paths.SitesChargepointsRetrieve.Responses.$200> => {
  const response = await axiosInstance.get(`/sites/chargepoints/${id}`);
  return response.data;
};

export const getSessions = async (nextPageUrl?: string): Promise<Paths.SessionList.Responses.$200> => {
  const url = nextPageUrl || '/sessions';
  const response = await axiosInstance.get(url);
  return response.data;
};

export const getCompletedSessions = async (nextPageUrl?: string): Promise<Paths.SessionList.Responses.$200> => {
  const url = nextPageUrl || '/completed-sessions';
  const response = await axiosInstance.get(url);
  return response.data;
};

export const getOngoingSessions = async (nextPageUrl?: string): Promise<Paths.SessionList.Responses.$200> => {
  const url = nextPageUrl || '/ongoing-sessions';
  const response = await axiosInstance.get(url);
  return response.data;
};

export const getTodaySessions = async (nextPageUrl?: string, cardId?: string, userId?: string, chargepointId?: string): Promise<Paths.SessionList.Responses.$200> => {
  const params = new URLSearchParams();
  if (cardId) {
      params.append("card_id", cardId);  // Pass `card_id` as a query param
  }
  if (userId) {
    params.append("user_id", userId);
  }
  if (chargepointId) {
    params.append("chargepoint_id", chargepointId);
  }
  const url = nextPageUrl ? nextPageUrl : `/today-sessions?${params.toString()}`;
  const response = await axiosInstance.get(url);
  return response.data;
};

export const getYesterdaySessions = async (nextPageUrl?: string, cardId?: string, userId?: string, chargepointId?: string): Promise<Paths.SessionList.Responses.$200> => {
  const params = new URLSearchParams();
  if (cardId) {
    params.append("card_id", cardId);  // Pass `card_id` as a query param
  }
  if (userId) {
    params.append("user_id", userId);
  }
  if (chargepointId) {
    params.append("chargepoint_id", chargepointId);
  }
  const url = nextPageUrl ? nextPageUrl : `/yesterday-sessions?${params.toString()}`;
  const response = await axiosInstance.get(url);
  return response.data;
};

export const getMonthSessions = async (nextPageUrl?: string, cardId?: string, userId?: string, chargepointId?: string): Promise<Paths.SessionList.Responses.$200> => {
  const params = new URLSearchParams();
  if (cardId) {
    params.append("card_id", cardId);  // Pass `card_id` as a query param
  }
  if (userId) {
    params.append("user_id", userId);
  }
  if (chargepointId) {
    params.append("chargepoint_id", chargepointId);
  }
  const url = nextPageUrl ? nextPageUrl : `/month-sessions?${params.toString()}`;
  const response = await axiosInstance.get(url);
  return response.data;
};

export const getYearSessions = async (nextPageUrl?: string, cardId?: string, userId?: string, chargepointId?: string): Promise<Paths.SessionList.Responses.$200> => {
  const params = new URLSearchParams();
  if (cardId) {
    params.append("card_id", cardId);  // Pass `card_id` as a query param
  }
  if (userId) {
    params.append("user_id", userId);
  }
  if (chargepointId) {
    params.append("chargepoint_id", chargepointId);
  }
  const url = nextPageUrl ? nextPageUrl : `/year-sessions?${params.toString()}`;
  const response = await axiosInstance.get(url);
  return response.data;
};

export const getSessionDetails = async (id: UUID): Promise<Paths.SessionDetails.Responses.$200> => {
  const response = await axiosInstance.get(`/sessions/${id}`);
  return response.data;
};

export const getSiteList = async (): Promise<Paths.SitesList.Responses.$200> => {
  const response = await axiosInstance.get(`/sites`);
  return response.data;
}

export const getSite = async (id: UUID): Promise<Paths.SitesRetrieve.Responses.$200> => {
  const response = await axiosInstance.get(`/sites/${id}`);
  return response.data;
}

export const postSiteUpdate = async (id: UUID): Promise<Paths.SitesUpdateCreate.Responses.$200> => {
  const response = await axiosInstance.post(`/sites/update/${id}`);
  return response.data;
}

export const getCardsList = async (nextPageUrl?: string): Promise<Paths.CardsList.Responses.$200> => {
  const url = nextPageUrl || '/cards';
  const response = await axiosInstance.get(url);
  return response.data;
}

export const getUserList = async (nextPageUrl?: string): Promise<Paths.UsersList.Responses.$200> => {
  const url = nextPageUrl || '/users';
  const response = await axiosInstance.get(url);
  return response.data;
}

export const postStartStopSession = async (id: UUID, action: string): Promise<any> => {
  // change Charging to Session
  const response = await axiosInstance.post(`/chargepoints/${id}/start_stop_charging`, { action });
  return response.data;
};

export const getCardDetails = async (id: UUID): Promise<Paths.CardsRetrieve.Responses.$200> => {
  const response = await axiosInstance.get(`/cards/${id}`);
  return response.data;
}

export const getUserDetails = async (id: UUID): Promise<Paths.UsersRetrieve.Responses.$200> => {
  const response = await axiosInstance.get(`/users/${id}`);
  return response.data;
}