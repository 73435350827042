import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { calculateDuration } from '../utils/utils';
import { useTranslation } from 'react-i18next';

import useOngoingSessions from '../hooks/useOngoingSessions';
import useTodaySessions from '../hooks/useTodaySessions';
import useYesterdaySessions from '../hooks/useYesterdaySessions';
import useMonthSessions from '../hooks/useMonthSessions';
import useYearSessions from '../hooks/useYearSessions';

import Table, { TableColumn, TableRow } from '../components/basics/table';
import ButtonGroup from '../components/buttons/ButtonGroup';
import Summary from '../components/Summary';
import ErrorPage from '../pages/ErrorPage';
import { TfiLayoutLineSolid } from "react-icons/tfi";

interface SessionsTableProps {
    availableViews: ("ongoing" | "today" | "yesterday" | "month" | "year")[];
    defaultView?: "ongoing" | "today" | "yesterday" | "month" | "year";
    cardId?: string;
    userId?: string;
    chargepointId?: string;
    showHeader?: boolean;
}

const SessionsTable: React.FC<SessionsTableProps> = ({ availableViews, defaultView = "ongoing", cardId, userId, chargepointId, showHeader }) => {
    const [view, setView] = useState(defaultView);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [loadingMore, setLoadingMore] = useState(false);
    const [offsetTop, setOffsetTop] = useState(0);

    // Fetch data for each view
    const ongoing = useOngoingSessions();
    const today = useTodaySessions({cardId, userId, chargepointId});
    const yesterday = useYesterdaySessions({cardId, userId, chargepointId});
    const month = useMonthSessions({cardId, userId, chargepointId});
    const year = useYearSessions({cardId, userId, chargepointId});

    const sessions =
        view === "today" ? today.sessions :
        view === "yesterday" ? yesterday.sessions :
        view === "month" ? month.sessions :
        view === "year" ? year.sessions :
        ongoing.sessions;

    const loading =
        view === "today" ? today.loading :
        view === "yesterday" ? yesterday.loading :
        view === "month" ? month.loading :
        view === "year" ? year.loading :
        ongoing.loading;

    const error =
        view === "today" ? today.error :
        view === "yesterday" ? yesterday.error :
        view === "month" ? month.error :
        view === "year" ? year.error :
        ongoing.error;
    
    const nextPage =
        view === "today" ? today.nextPage :
        view === "yesterday" ? yesterday.nextPage :
        view === "month" ? month.nextPage :
        view === "year" ? year.nextPage :
        ongoing.nextPage;
    
    const count =
        view === "today" ? today.count :
        view === "yesterday" ? yesterday.count :
        view === "month" ? month.count :
        view === "year" ? year.count :
        ongoing.count;

    const summaryStat =
        view === "today" ? today.summaryStat :
        view === "yesterday" ? yesterday.summaryStat :
        view === "month" ? month.summaryStat :
        view === "year" ? year.summaryStat :
        ongoing.summaryStat;

    const total_energy = summaryStat?.total_energy ?? 0
    const avg_energy = summaryStat?.avg_energy ?? 0

    const avg_count = summaryStat?.avg_count ?? 0

    const total_amount = summaryStat?.total_amount ?? 0
    const avg_amount = summaryStat?.avg_amount ?? 0

    useEffect(() => {
        const calculateOffsetTop = () => {
            const navbarHeight = 64;
            const gapSize = 8;
            const additionalOffset = 0;
            setOffsetTop(navbarHeight + gapSize * 2 + additionalOffset + 150);
        };

        calculateOffsetTop();
        window.addEventListener('resize', calculateOffsetTop);

        return () => {
            window.removeEventListener('resize', calculateOffsetTop);
        };
    }, []);

    const viewRef = useRef(view);
    const nextPageRef = useRef(nextPage);
    const loadingMoreRef = useRef(loadingMore);
    
    useEffect(() => {
        viewRef.current = view;
    }, [view]);

    useEffect(() => {
        nextPageRef.current = nextPage;
    }, [nextPage]);

    useEffect(() => {
        loadingMoreRef.current = loadingMore;
    }, [loadingMore]);

    const handleFetchNextPage = async () => {
        if (loadingMoreRef.current || loading || !nextPageRef.current) return;
    
        setLoadingMore(true);
        if (viewRef.current === "today") {
            await today.fetchNextTodayPage();
        } else if (viewRef.current === "yesterday") {
            await yesterday.fetchNextYesterdayPage();
        } else if (viewRef.current === "month") {
            await month.fetchNextMonthPage();
        } else if (viewRef.current === "year") {
            await year.fetchNextYearPage();
        } else {
            await ongoing.fetchNextOngoingPage();
        }
        setLoadingMore(false);
      };
    
    const summaryHeaders = ['Sessions', 'kWh', 'Euros'];
    const summaryValues = [
        { total: count, perDay: `${avg_count.toFixed(0)} /day` }, //TODO: make sure to round up
        { total: (total_energy / 1000 ).toFixed(2), perDay: `${(avg_energy / 1000 ).toFixed(2)} kWh/day` },
        { total: `€ ${total_amount}`, perDay: `€ ${avg_amount} /day` }
    ];

    const columns: TableColumn[] = view === "ongoing"
        ? [
            { header: t(''), key: 'row_number' },
            { header: t('start time'), key: 'start' },
            { header: t('duration'), key: 'duration' },
            { header: `${t('energy')} (kWh)`, key: 'energy_kwh' },
            { header: t('charging station'), key: 'charging_station' },
            { header: t('tariff'), key: 'tariff_name' },
            { header: t('amount (incl. VAT)'), key: 'amount' },
            { header: t('user'), key: 'user_name' },
        ]
        : [
            { header: t(''), key: 'row_number' },
            { header: t('start time'), key: 'start_date' },
            { header: t('end time'), key: 'completed_at' },
            { header: t('duration'), key: 'duration' },
            { header: `${t('energy')} (kWh)`, key: 'energy_kwh' },
            { header: t('charging station'), key: 'charging_station' },
            { header: t('tariff'), key: 'tariff_name' },
            { header: t('amount (incl. VAT)'), key: 'amount' },
            { header: t('user'), key: 'user_name' },
        ];

    const tableData: TableRow[] = sessions.map((session, index) => ({
        row_number: index + 1,
        start_date: session.active_at ? new Date(session.active_at).toLocaleString() : session.start ? new Date(session.start).toLocaleString() : '-',
        completed_at: session.completed_at ? new Date(session.completed_at).toLocaleString() : '-',
        duration: session.completed_at ? calculateDuration(session.start, session.completed_at) : calculateDuration(session.start, new Date().toISOString()),
        energy_kwh: (session.energy / 1000).toFixed(2),
        charging_station: session.chargepoint_name,
        start: new Date(session.start).toLocaleString(),
        tariff_name: session.tariff ? session.tariff : '-',
        amount: session.total_cost ? session.total_cost.toFixed(2) : '0.00',
        user_name: session.user_name ? session.user_name : session.token_uid ? session.token_uid : 'AD HOC USER',
        uuid: session.uuid,
    }));

    if (error) {
        return <ErrorPage errorCode={500} errorMessage={error} />;
    }

    return (
        <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
            {/* Show header on User, Card and Chargepoint pages */}
            {showHeader && (
                <h2 className="mb-2 text-lg font-body font-semibold uppercase text-txt-lg-header dark:text-txt-dk-header">{t('sessions')}</h2>
            )}
            <Summary headers={summaryHeaders} values={summaryValues} offsetTop={offsetTop} />

            <div className="flex justify-left mb-4">
                <ButtonGroup
                    view={view}
                    buttons={availableViews.map(viewKey => ({ key: viewKey, label: t(viewKey) }))}
                    setView={setView}
                />
            </div>
            
            <Table
                table_name={t('sessions')}
                columns={columns}
                data={tableData}
                onRowClick={(row) => navigate(`/sessions/${row.uuid}`)}
                loading={loading && tableData.length === 0}
                loadingMore={loadingMore}
                offsetTop={offsetTop}
                isScrollable={true}
                onLoadMore={handleFetchNextPage}
            />

            {!loading && !nextPage && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TfiLayoutLineSolid size={32} />
                </div>
            )}
        </div>
    );
};

export default SessionsTable;
