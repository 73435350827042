import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useCardsList from '../hooks/useCardslist';
import { filterData } from '../utils/filterUtils';
import ErrorPage from './ErrorPage';

// components
import Heading from '../components/typography/Heading';
import Table, { TableColumn, TableRow } from '../components/basics/table';
import SearchInput from '../components/SearchInput';

const CardsPage: React.FC = () => {
    const { cards, error, loading, nextPage, fetchNextCardPage } = useCardsList();
    const [loadingMore, setLoadingMore] = useState(false);
    const [offsetTop, setOffsetTop] = useState(0);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState<TableRow[]>([]);

    const cardColumns: TableColumn[] = [
        { header: t(""), key: "row_number" },
        { header: t("company"), key: "company" },
        { header: t("user"), key: "user" },
        { header: t("number"), key: "number" },
        { header: t("badge ID"), key: "badge_id" },
        { header: t("description"), key: "desc" },
        { header: t("status"), key: "enabled" },
    ];

    useEffect(() => {
        const calculateOffsetTop = () => {
            const navbarHeight = 64;
            const gapSize = 8;
            const additionalOffset = 0;
            setOffsetTop(navbarHeight + gapSize * 2 + additionalOffset + 150);
        };
    
        calculateOffsetTop();
        window.addEventListener('resize', calculateOffsetTop);
    
        return () => {
            window.removeEventListener('resize', calculateOffsetTop);
        };
    }, []);

    useEffect(() => {
        // search based on all criterials in the table
        const criteria = { number: searchQuery, company: searchQuery, user: searchQuery, desc: searchQuery, enabled: searchQuery, baddgeId: searchQuery, };
        const mappedData = cards
            .map((card, index) => ({
                row_number: index + 1,
                uuid: card.uuid as string,
                company: card?.user_company || 'N/A',
                number: card.number,
                badge_id: card.badge_id || '',
                user: card?.user_email || card?.user_name,
                desc: card.desc || '',
                enabled: card.enabled ? "Active" : "Inactive" //TODO: Make it green or red
            }));
        setFilteredData(filterData(mappedData, criteria));
    }, [cards, searchQuery]);

    if (error) {
        return <ErrorPage errorCode={500} errorMessage={error} />;
    }

    const handleFetchNextPage = async () => {
        if (loadingMore || loading || !nextPage) return;
        setLoadingMore(true);
        await fetchNextCardPage();
        setLoadingMore(false);
      };

    return (
        <div>
            <Heading
                title={t('Cards')}
                highlight={t('charging')}
                description={t('All the Charging Cards')}
            />
            <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body flex flex-col items-center">
                <div className="w-full flex justify-center mb-6">
                    <SearchInput
                        id="search"
                        value={searchQuery}
                        onChange={setSearchQuery} // Directly set the search query
                        displayKey="number" // Specify the key to display (kept for clarity)
                    />
                </div>
                <div className="w-full">
                    <Table 
                        table_name="Cards" 
                        columns={cardColumns} 
                        data={filteredData} 
                        onRowClick={(row) => navigate(`/cards/${row.uuid}`)}
                        loading={loading && filteredData.length === 0}
                        loadingMore={loadingMore}
                        offsetTop={offsetTop}
                        isScrollable={true}
                        onLoadMore={handleFetchNextPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default CardsPage;
