import { useState, useEffect } from 'react';
import { getSessionDetails } from '../services/apiService';
import { Components } from '../api/client';
import { UUID } from 'crypto';

type Session = Components.Schemas.Session;
type Cdr = Components.Schemas.CDR;
type Tenant = Components.Schemas.Tenant;

const useChargingDetails = (id: UUID) => {
  const [sessionDetails, setSessionDetails] = useState<Session>();
  const [cdrDetails, setCdrDetails] = useState<Cdr>();
  const [tenantDetails, setTenantDetails] = useState<Tenant>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const data = await getSessionDetails(id);
        if (data && data.charging) {
          // Adjust the energy field (to kwh not wh)
          const adjustedChargingDetails = {
            ...data.charging,
            energy: data.charging.energy / 1000,
          };
          setSessionDetails(adjustedChargingDetails);
        } else {
          setSessionDetails(data.charging);
        }
        // ongoing sessions have no cdr yet
        if (data.cdr) {
          setCdrDetails(data.cdr);
        }
        if (data.tenant) {
          setTenantDetails(data.tenant);
        }
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch charging session details.');
      }
    };
    fetchData();
  }, [id]);

  return { sessionDetails, cdrDetails, tenantDetails, error, loading }
};

export default useChargingDetails;