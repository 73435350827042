import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useMenuItems, useNavItems } from './menuItems';
import Navbar from './NavBar';
import Sidebar from './SideBar';
import Breadcrumb from '../menu/BreadCrumb';
import { motion, AnimatePresence } from 'framer-motion';

import logoLight from '../../images/logos/enerando_logo_rgb_noclaim.png';
import logoDark from '../../images/logos/enerando_logo_rgb_wei├ƒ_noclaim.png';

const BaseMenu: React.FC = () => {
    const { loading } = useAuth();
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const menuItems = useMenuItems();
    const navItems = useNavItems();
    const navbarHeight = 64; // Adjust as necessary
    const gapSize = 8;

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowWidth < 1024) {
            setSidebarOpen(false);
        } else {
            setSidebarOpen(true);
        }
    }, [windowWidth]);

    const isOverlay = windowWidth < 1024 && sidebarOpen;

    // Prevent background scrolling when sidebar is open in overlay mode
    useEffect(() => {
        document.body.style.overflow = 'hidden'; // Always prevent body from scrolling
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    const totalOffset = navbarHeight + gapSize * 2;

    return (
        <div className="relative flex min-h-screen bg-bg-lg-bg dark:bg-bg-dk-bg">
            <Navbar
                brandLink="/"
                menuItems={navItems}
                toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
                loading={loading}
                windowWidth={windowWidth}
                gapSize={gapSize}
                navbarHeight={navbarHeight}
            />
            <Sidebar
                menuItems={menuItems}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                loading={loading}
                navbarHeight={navbarHeight}
                gapSize={gapSize}
                windowWidth={windowWidth}
            />

            {/* Overlay */}
            <AnimatePresence>
                {isOverlay && sidebarOpen && (
                    <motion.div
                        key="overlay"
                        onClick={() => setSidebarOpen(false)}
                        className="fixed inset-0 bg-black z-30"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.5 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    ></motion.div>
                )}
            </AnimatePresence>

            {/* Main Content */}
            <div
                className={`flex flex-col flex-1 transition-all duration-300 ${isOverlay ? 'filter blur-sm' : ''
                    }`}
                style={{
                    marginLeft:
                        sidebarOpen && windowWidth >= 1024
                            ? `calc(${256 + gapSize * 2}px)`
                            : `${gapSize}px`,
                    marginRight: `${gapSize}px`,
                    marginTop: `${navbarHeight + gapSize}px`,
                    height: `calc(100vh - ${totalOffset}px)`,
                    overflowY: 'auto',
                }}
            >
                <div className="p-4 text-txt-lg-body dark:text-txt-dk-body">
                    <Breadcrumb />
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default BaseMenu;
