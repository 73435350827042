import { createBrowserRouter } from 'react-router-dom';
import authLoader from './authLoader';

// pages
import HomePage from '../pages/HomePage';
import SessionsPage from '../pages/SessionsPage';
import SessionDetailsPage from '../pages/SessionsDetailsPage';
import NotFoundPage from '../pages/NotFoundPage';
import BaseMenu from '../components/menu/BaseMenu';
import LoginPage from '../pages/LoginPage';
import InfrastructurePage from '../pages/InfrastructurePage';
import ChargepointDetailsPage from '../pages/ChargepointDetailsPage';
// import SitePage from '../pages/SitePage';
import CardsPage from '../pages/CardsPage';
import CardDetailPage from '../pages/CardDetailPage'
import UsersPage from '../pages/UsersPage';
import UserDetailPage from '../pages/UserDetailPage';

// TODO: change routes for infrastructure to have site, pool, fuse, charegpoint logic?


const router = createBrowserRouter([
    {
        path: '/',
        element: <BaseMenu />,
        children: [
            {
                index: true,
                element: <HomePage />,
                loader: authLoader, // redirect to login
            },
            {
                path: 'sessions',
                element: <SessionsPage />,
                loader: authLoader, // redirect to login
            },
            {
                path: 'sessions/:id',
                element: <SessionDetailsPage />,
                loader: authLoader, // redirect to login
            },
            {
                path: 'infrastructure',
                element: <InfrastructurePage />,
                loader: authLoader, // redirect to login
            },
            {
                path: 'infrastructure/:id',
                element: <ChargepointDetailsPage />,
                loader: authLoader, // redirect to login
            },
            // {
            //     path: 'site',
            //     element: <SitePage/>,
            //     loader: authLoader, // redirect to login
            // },
            {
                path: 'cards',
                element: <CardsPage />,
                loader: authLoader, // redirect to login
            },
            {
                path: 'cards/:id',
                element: <CardDetailPage />,
                loader: authLoader, // redirect to login
            },
            {
                path: 'users',
                element: <UsersPage />,
                loader: authLoader, // redirect to login
            },
            {
                path: 'users/:id',
                element: <UserDetailPage />,
                loader: authLoader, // redirect to login
            },
            {
                path: 'login',
                element: <LoginPage />,
            },
            {
                path: '*',
                element: <NotFoundPage />,
            },
        ],
    },
]);

export default router;
