import { useState, useEffect, useRef } from "react";
import { getUserList } from "../services/apiService";
import { Components } from "../api/client";

type User = Components.Schemas.User;
type ResponseUserList = Components.Schemas.ResponseUserList;

const useUserList = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [nextPage, setNextPage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchUsers = async (nextPageUrl?: string) => {
        try {
            setLoading(true);
            const data: ResponseUserList = await getUserList(nextPageUrl);
            setUsers((prev) => {
                const combined = [...prev, ...data.users.results];
                const uniqueUsers = Array.from(
                    new Map(combined.map((user) => [user.uuid, user])).values()
                );
                return uniqueUsers;
            });
            setNextPage(data.users.next);
        } catch (err) {
            console.error(err);
            setError('Failed to fetch the yearly sessions.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers(); // Initial fetch
    }, []);

    const nextPageRef = useRef(nextPage);

    useEffect(() => {
        nextPageRef.current = nextPage; // Sync ref with state
    }, [nextPage]);

    const fetchNextUserPage = async () => {
        if (nextPageRef.current) {
            await fetchUsers(nextPageRef.current);
        }
    };

    return { users, loading, error, nextPage, fetchNextUserPage };
};

export default useUserList;