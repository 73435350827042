import React from 'react';
import { useTranslation } from 'react-i18next';
import Heading from '../components/typography/Heading';
import SessionsTable from '../components/SessionsTable';

const SessionsPage: React.FC = () => {
    const { t } = useTranslation();

    return (
      <div>
        <Heading
          title={t('sessions')}
          highlight={t('charging')}
          description={t('Current and historic session details.')}
        />
        <SessionsTable 
          availableViews={["ongoing", "today", "yesterday", "month", "year"]} 
        />
      </div>
    );
};

export default SessionsPage;
