import { motion } from 'framer-motion';
import React from 'react';
import { useState, useEffect } from 'react';

interface StatGridProps {
  headers: string[];
  values: Array<{ total: string | number; perDay: string | number }>;
  offsetTop?: number;
}

const StatGrid: React.FC<StatGridProps> = ({ headers, values, offsetTop = 0 }) => {
    const [availableHeight, setAvailableHeight] = useState<number>(0);
  
    useEffect(() => {
        const calculateHeight = () => {
            if (typeof window !== 'undefined') {
                setAvailableHeight(window.innerHeight - offsetTop);
            }
        };

        window.addEventListener('resize', calculateHeight);
        calculateHeight();

        return () => {
            window.removeEventListener('resize', calculateHeight);
        };
    }, [offsetTop]);

  return (
    <div 
      className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-8 min-h-full rounded-2xl"
    >
      <div className="w-full flex flex-col space-y-4 flex-1"></div> 
      
      <div 
        className="max-w-lg md:w-1/2 flex-1 rounded-2xl"
        style={{ maxHeight: availableHeight > 0 ? `${availableHeight}px` : undefined, overflowY: 'auto' }}
      >
        <motion.div
          className="grid gap-1 rounded-2xl bg-white dark:bg-bg-dk-content"
          style={{ gridTemplateColumns: `repeat(${headers.length}, minmax(150px, 1fr))` }} // Ensures exact number of columns
        >
          {headers.map((header, index) => (
            <motion.div
              key={header}
              className="dark:bg-gray-800 p-4 rounded-2xl shadow-sm dark:border-gray-700 text-left bg-white dark:bg-bg-dk-content"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 * index }}
            >
              {/* Header */}
              <div className="text-sm font-semibold text-gray-700 dark:text-gray-200">
                {header}
              </div>
              {/* Totals */}
              <motion.div className="text-lg font-bold text-enerando-green mt-2">
                {values[index]?.total}
              </motion.div>

              {/* PerDay */}
              <motion.div className="text-xs font-medium text-gray-500 dark:text-gray-400 mt-1">
                {values[index]?.perDay}
              </motion.div>              
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default StatGrid;
