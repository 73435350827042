import React, { useEffect, useState } from 'react';
import Button, { ButtonVariant } from '../buttons/Button';
import LinkButton from '../buttons/LinkButton';
import { Link } from 'react-router-dom';
import { isDarkMode } from '../../utils/utils';
import Spinner from '../Spinner';
import { Components } from '../../api/client';
import { motion } from 'framer-motion';

type ChargepointStatus = Components.Schemas.Chargepoint['status'];

export interface CardProps {
    title?: string;
    description?: string;
    href?: string;
    buttonText?: string;
    buttonOnClick?: () => void;
    buttonVariant?: ButtonVariant;
    children?: React.ReactNode;
    isAuthenticated?: boolean;
    requireAuth?: boolean;
    loading?: boolean;
    exportButton?: React.ReactNode;
    fullWidth?: boolean;
    titleFontSize?: string;
    showStatusIcon?: boolean;
    status?: ChargepointStatus;
    alignment?: 'left' | 'center';
    hoverEffect?: 'default' | 'card';
}

const Card: React.FC<CardProps> = ({
    title,
    description,
    href,
    buttonText,
    buttonOnClick,
    buttonVariant,
    children,
    isAuthenticated,
    requireAuth,
    loading,
    exportButton,
    fullWidth = false,
    titleFontSize = 'text-base',
    status,
    alignment = 'left',
    hoverEffect = 'default',
}) => {
    const [darkMode, setDarkMode] = useState(isDarkMode());

    useEffect(() => {
        const handleDarkModeChange = (e: MediaQueryListEvent) => {
            setDarkMode(e.matches);
        };

        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        darkModeMediaQuery.addEventListener('change', handleDarkModeChange);

        return () => {
            darkModeMediaQuery.removeEventListener('change', handleDarkModeChange);
        };
    }, []);

    const titleStyle = titleFontSize ? { fontSize: titleFontSize } : {};
    const alignmentClass = alignment === 'center' ? 'text-center' : 'text-left';

    // Map status to label and color
    const statusMapping: Record<string, { label: string; color: string }> = {
        AVAILABLE: { label: 'Available', color: 'rgba(68, 214, 44, 1)' },
        CHARGING: { label: 'Charging', color: 'rgba(9, 170, 205, 1)' },
        BLOCKED: { label: 'Blocked', color: 'rgba(255, 165, 0, 1)' },
        INOPERATIVE: { label: 'Inoperative', color: 'rgba(255, 0, 0, 1)' },
        OUTOFORDER: { label: 'Out of Order', color: 'rgba(128, 0, 128, 1)' },
        PLANNED: { label: 'Planned', color: 'rgba(0, 128, 128, 1)' },
        REMOVED: { label: 'Removed', color: 'rgba(128, 128, 128, 1)' },
        RESERVED: { label: 'Reserved', color: 'rgba(255, 215, 0, 1)' },
        UNKNOWN: { label: 'Unknown', color: 'rgba(169, 169, 169, 1)' },
    };

    const currentStatus = statusMapping[status ?? 'UNKNOWN'];
    const statusLabel = currentStatus.label;
    const statusColor = currentStatus.color;

    const darkModeClass = darkMode ? 'dark' : '';

    const cardContent = (
        <div className="flex flex-col flex-grow w-full card-content relative">
            <div className={`flex flex-col flex-grow ${alignmentClass}`}>
                <div className={`flex ${alignment === 'center' ? 'justify-center' : 'justify-between'} w-full`}>
                    {loading ? (
                        <div className="flex justify-center items-center h-full">
                            <Spinner size={32} />
                        </div>
                    ) : href && hoverEffect !== 'card' ? (
                        <Link to={href}>
                            <h5
                                className={`mb-2 font-body font-semibold tracking-tight text-txt-lg-header hover:text-enerando-green dark:text-txt-dk-header ${alignmentClass}`}
                                style={titleStyle}
                            >
                                {title}
                            </h5>
                        </Link>
                    ) : (
                        <h5
                            className={`mb-2 font-body font-semibold tracking-tight text-txt-lg-header dark:text-txt-dk-header ${alignmentClass}`}
                            style={titleStyle}
                        >
                            {title}
                        </h5>
                    )}
                    {exportButton && <div className="ml-2">{exportButton}</div>}
                </div>
                {loading ? (
                    <div className="flex justify-center items-center h-full">
                        <Spinner size={32} />
                    </div>
                ) : (
                    <p className={`mb-3 font-body text-txt-lg-body dark:text-txt-dk-body text-sm ${alignmentClass}`}>{description}</p>
                )}
                {requireAuth && !isAuthenticated ? (
                    <p className={`mb-3 font-body text-txt-lg-body dark:text-txt-dk-body text-sm ${alignmentClass}`}>
                        You need to log in to see this content.
                    </p>
                ) : (
                    <div className="flex-grow">{children}</div>
                )}
            </div>
            {buttonText && (
                <div className="mt-4 flex justify-end">
                    {loading ? (
                        <div className="flex justify-center items-center h-full">
                            <Spinner size={32} />
                        </div>
                    ) : href && hoverEffect !== 'card' ? (
                        <LinkButton to={href} text={buttonText} variant={buttonVariant} />
                    ) : (
                        <Button text={buttonText} onClick={buttonOnClick} variant={buttonVariant} />
                    )}
                </div>
            )}
        </div>
    );

    // Define animations based on status
    const statusAnimations: Record<string, string> = {
        AVAILABLE: 'static-outline',
        CHARGING: 'pulsating-outline',
        BLOCKED: 'static-outline',
        INOPERATIVE: 'static-outline',
        OUTOFORDER: 'static-outline',
        PLANNED: 'static-outline',
        REMOVED: 'static-outline',
        RESERVED: 'static-outline',
        UNKNOWN: 'none',
    };

    const statusAnimation = statusAnimations[status ?? 'UNKNOWN'];

    // Function to render the card with appropriate animations
    const renderCard = () => {
        const commonProps = {
            className: `p-6 rounded-2xl flex flex-col justify-between h-full w-full ${fullWidth ? 'w-full' : 'max-w-md'
                } ${darkModeClass} relative`,
            style: {
                backgroundColor: hoverEffect === 'card' ? undefined : darkMode ? 'var(--bg-dk-bg)' : 'var(--bg-lg-bg)',
            },
        };

        // Apply hover effects only for certain statuses
        const hoverProps =
            statusAnimation !== 'none'
                ? {
                    whileHover: { scale: 1.03 },
                    whileTap: { scale: 0.97 },
                }
                : {};

        if (statusAnimation === 'pulsating-outline') {
            return (
                <motion.div {...commonProps} {...hoverProps}>
                    {/* Pulsating Animation */}
                    <motion.div
                        className="absolute inset-0 rounded-2xl pointer-events-none"
                        animate={{
                            boxShadow: [
                                `inset 0 0 0 1px ${statusColor}`,
                                `inset 0 0 25px 5px ${statusColor}`,
                            ],
                        }}
                        transition={{
                            duration: 1.5,
                            repeat: Infinity,
                            repeatType: 'reverse',
                            ease: 'easeInOut',
                        }}
                    />
                    {/* Status Label */}
                    <div className="status-label" style={{ color: statusColor }}>
                        {statusLabel}
                    </div>
                    {cardContent}
                </motion.div>
            );
        } else if (statusAnimation === 'static-outline') {
            return (
                <motion.div
                    {...commonProps}
                    {...hoverProps}
                    style={{
                        ...commonProps.style,
                        boxShadow: `inset 0 0 15px 5px ${statusColor}`,
                        border: `1px solid ${statusColor}`,
                    }}
                >
                    {/* Status Label */}
                    <div className="status-label" style={{ color: statusColor }}>
                        {statusLabel}
                    </div>
                    {cardContent}
                </motion.div>
            );
        } else {
            // Default card without animations
            return (
                <div
                    className={`p-6 bg-bg-lg-content rounded-2xl dark:bg-bg-dk-content flex flex-col justify-between h-full w-full ${fullWidth ? 'w-full' : 'max-w-md'
                        } shadow-sm`}
                >
                    {cardContent}
                </div>
            );
        }
    };

    // Wrap the card with Link if needed
    if (hoverEffect === 'card') {
        return href ? (
            <Link to={href} className="block h-full w-full">
                {renderCard()}
            </Link>
        ) : (
            renderCard()
        );
    }

    return renderCard();
};

export default Card;
