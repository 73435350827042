import { motion } from 'framer-motion';
import React, { Dispatch, SetStateAction } from 'react';


interface ButtonGroupProps<T extends string> {
  view: T;
  buttons: { key: T; label: string }[];
  setView: Dispatch<SetStateAction<T>>;  // This matches the type of useState's setState function
}

const ButtonGroup = <T extends string>({ view, buttons, setView }: ButtonGroupProps<T>) => {
  return (
    <div className="inline-flex rounded-2xl shadow-sm">
      {buttons.map(({ key, label }) => (
        <motion.button
          key={key}
          onClick={() => setView(key)}
          className={`px-4 py-2 text-sm font-medium ${
            view === key
              ? 'bg-enerando-green text-white dark:bg-enerando-green'
              : 'text-txt-lg-body bg-bg-lg-content dark:bg-bg-dk-item dark:text-txt-dk-body'
          } border border-border-lg first:rounded-l-lg last:rounded-r-lg hover:bg-hover-lg hover:text-txt-lg-placeholder dark:hover:text-txt-dk-placeholder dark:hover:bg-hover-dk dark:border-border-dk`}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          {label}
        </motion.button>
      ))}
    </div>
  );
};

export default ButtonGroup;
