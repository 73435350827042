import { useEffect, useState } from 'react';
import { isDarkMode } from '../utils/utils';

const useDarkMode = (): [boolean, () => void] => {
  const [darkMode, setDarkMode] = useState<boolean>(() => {
    // Check if user has a manual preference
    const storedPreference = localStorage.getItem('darkMode');
    if (storedPreference !== null) {
      return JSON.parse(storedPreference); // Use stored preference if it exists
    }
    return isDarkMode(); // Otherwise, use system preference
  });

  // Listen for system preference changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = () => {
      if (!localStorage.getItem('darkMode')) {
        setDarkMode(mediaQuery.matches); // Update based on system preference
      }
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  // Apply the dark class to the HTML element
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  // Toggle dark mode manually
  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem('darkMode', JSON.stringify(newMode)); // Persist user preference
  };

  return [darkMode, toggleDarkMode];
};

export default useDarkMode;
