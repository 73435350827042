import { useState, useEffect } from 'react';
import { getCardDetails } from '../services/apiService';
import { Components } from '../api/client';
import { UUID } from 'crypto';

type CardDetails = Components.Schemas.CardDetail;
type ResponseCardDetails = Components.Schemas.ResponseCardDetails

const useCardDetails = (id: UUID) => {
    const [cardDetails, setCardDetails] = useState<CardDetails>();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data: ResponseCardDetails = await getCardDetails(id);
                setCardDetails(data.card);
            } catch (error) {
                setError('Failed to fetch card details.');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    return { cardDetails, error, loading }
};

export default useCardDetails;