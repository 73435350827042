import { useState } from 'react'
import { UUID } from 'crypto'
import { postStartStopSession } from '../services/apiService';


const useStartStopSession = ( id: UUID ) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const startStopSession = async (action: string) => {
        setLoading(true);
        setError(null);
        try {
            const response = await postStartStopSession(id, action);
            return response;
        } catch (err) {
            setError('Failed to start/stop session.');
            console.error(err)
        } finally {
            setLoading(false);
        }
    };

    return { startStopSession, loading, error };
};

export default useStartStopSession;