import React from 'react';
import { FaBars } from "react-icons/fa6";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';
import Logo from '../../images/logos/Logo';
import { motion } from 'framer-motion';

import { useAuth } from '../../contexts/AuthContext';
import UserMenu from './UserMenu';
import DarkModeToggle from './DarkModeToggle';

type NavbarProps = {
    brandLink: string;
    menuItems: { label: string; link: string }[];
    toggleSidebar: () => void;
    loading: boolean;
    windowWidth: number;
    gapSize: number;
    navbarHeight: number;
};

const Navbar: React.FC<NavbarProps> = ({ brandLink, menuItems, toggleSidebar, loading, windowWidth, gapSize, navbarHeight }) => {
    const { isAuthenticated, logout } = useAuth();

    const showLogo = windowWidth >= 768; // Adjust the width as necessary

    return (
        <nav
            className="fixed top-0 z-50 flex items-center bg-bg-lg-content dark:bg-bg-dk-content rounded-2xl"
            style={{
                left: `${gapSize}px`,
                right: `${gapSize}px`,
                top: `${gapSize}px`,
                height: `${navbarHeight}px`,
            }}
        >
            <div className="flex items-center justify-between w-full px-3 py-3 lg:px-5 lg:pl-3">
                {/* Left Section */}
                <div className="flex items-center">
                    {/* Sidebar Toggle Button */}
                    <button
                        onClick={toggleSidebar}
                        aria-controls="logo-sidebar"
                        type="button"
                        className="inline-flex items-center p-2 text-icon-lg dark:text-icon-dk rounded-2xl hover:text-enerando-green"
                    >
                        <FaBars className="w-5 h-5" aria-hidden="true" />
                        <span className="sr-only">Toggle sidebar</span>
                    </button>

                    {/* Logo */}
                    {showLogo && (
                        <>
                            {brandLink ? (
                                <Link to={brandLink}>
                                    <div className="h-8 w-40 ml-2">
                                        <Logo />
                                    </div>
                                </Link>
                            ) : (
                                <div className="h-8 w-40 ml-2">
                                    <Logo />
                                </div>
                            )}
                        </>
                    )}
                </div>

                {/* Right Section */}
                <div className="flex items-center space-x-4">
                    {<DarkModeToggle />}
                    
                    {!loading &&
                        menuItems.map((item, index) => (
                            <motion.a
                                key={index}
                                href={item.link}
                                className="font-body font-normal text-txt-lg-header dark:text-txt-dk-header hover:text-enerando-green"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                {item.label}
                            </motion.a>
                        ))}

                    {!loading && <UserMenu isAuthenticated={isAuthenticated} onLogout={logout} />}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
