import React from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UUID } from "crypto";
import ErrorPage from "./ErrorPage";
import useCardDetails from "../hooks/useCardDetails";
import Heading from '../components/typography/Heading';
import CardGrid, { CardItem, CardType } from "../components/positioning/CardGrid";
import SessionsTable from '../components/SessionsTable';

// icons
import { AiOutlineNumber } from "react-icons/ai";
import { FaCheckCircle } from 'react-icons/fa'; // change this
// import { MdSensorOccupied } from "react-icons/md"; // tenant
import { MdDateRange, MdAlternateEmail } from "react-icons/md"; // created
import { HiOfficeBuilding } from "react-icons/hi";
import {FaCreditCard } from "react-icons/fa6";

const CardDetailPage: React.FC = () => {
    const { id } = useParams<{ id: UUID }>();
    const { cardDetails, error, loading } = useCardDetails(id!);
    const { t } = useTranslation();
    if (error) {
        return <ErrorPage errorCode={500} errorMessage={error} />;
    }
    const card = cardDetails;

    const cardCards: CardItem[] = [
        {
            type: CardType.Attribute,
            name: t('card number'),
            value: card?.number,
            loading: loading,
            icon: <AiOutlineNumber className={`text-lg text-enerando-green`} />,
        },
        {
            type: CardType.Attribute,
            name: t('badge_id'),
            value: card?.badge_id || card?.desc || 'N/A',
            loading: loading,
            icon: <FaCreditCard className={`text-lg text-enerando-green`} />,
        },
        {
            type: CardType.Attribute,
            name: t('status'),
            value: `${card?.enabled ? t('Active') : t('Inactive')}`,
            loading: loading,
            icon: <FaCheckCircle className={`text-lg ${card?.enabled ? 'text-enerando-green' : 'text-danger-base'}`} />,
        },
        {
            type: CardType.Attribute,
            name: t('user'),
            value: card?.user_email,
            loading: loading,
            icon: <MdAlternateEmail className={`text-lg text-enerando-green`} />,
        },
        // {
        //     type: CardType.Attribute,
        //     name: t('tenant'),
        //     value: card?.user.tenant.name,
        //     loading: loading,
        //     icon: <MdSensorOccupied className={`text-lg text-enerando-green`} />,
        // },
        {
            type: CardType.Attribute,
            name: t('created'),
            value: card?.created ? new Date(card?.created).toLocaleString() : 'N/A',
            loading: loading,
            icon: <MdDateRange className={`text-lg text-enerando-green`} />,

        },
        {
            type: CardType.Attribute,
            name: t('company'),
            value: card?.user_company || 'N/A',
            loading: loading,
            icon: <HiOfficeBuilding className={`text-lg text-enerando-green`} />
        }
    ]

    return (
        <div>
            <Heading
                title={t('Details')}
                highlight={t('Card')}
                description={t('All details about this card.')}
            />

            <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
                <CardGrid items={cardCards} loading={loading} />
                <div className="mt-20">
                    <SessionsTable 
                        availableViews={["today", "yesterday", "month", "year"]} 
                        defaultView="today"
                        cardId={id}
                        showHeader
                    />
                </div>
            </div>
        </div>
    );
};

export default CardDetailPage;