import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { motion } from 'framer-motion';
import useDarkMode from '../../hooks/useDarkMode';
import { FaSun, FaMoon } from "react-icons/fa";

const DarkModeToggle: React.FC = () => {
  const [darkMode, toggleDarkMode] = useDarkMode();

  return (
    <motion.button
        onClick={toggleDarkMode}
        className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600"
        aria-label="Toggle Dark Mode"
        >
        {darkMode ? <FaSun className="w-5 h-5 text-icon-lg dark:text-icon-dk hover:text-enerando-green" aria-hidden="true" /> : <FaMoon className="w-5 h-5 text-icon-lg dark:text-icon-dk hover:text-enerando-green" aria-hidden="true" />}
    </motion.button>
  );
};

export default DarkModeToggle;
