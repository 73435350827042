import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { formatDate } from '../utils/utils';

interface ReceiptProps {
    transactionId?: string | null;
    name?: string | null;
    address?: string | null;
    plz?: number | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    vat_id?: string | null;
    period?: string | null;
    energy?: number | null;
    time?: string | null;
    tariff?: number | null;
    net?: number | null;
    vat?: number | null;
    vatRate?: number | null;
    totalCost?: number | null;
    user?: string | null;
    token?: string | null;
    authorization?: string | null;
    start_fee?: number | null;
}

const Receipt: React.FC<ReceiptProps> = ({ transactionId, name, address, plz, city, state, country, vat_id, period, energy, time, tariff, net, vat, vatRate, totalCost, user, token, authorization, start_fee }) => {
    const [startDate, endDate] = period ? period.split(' - ') : ["", ""];
    const formattedStartDate = formatDate(startDate).replace(/ at /g, ", ");
    const formattedEndDate = formatDate(endDate).replace(/ at /g, ", ");

    const { t } = useTranslation();
    
    if (!formattedStartDate || !formattedEndDate) {
        return null; // or return an error message if preferred
    }

    return (
        <dl className="divide-y divide-gray-100 py-3 last:pb-0">
            <table className="font-mono text-sm text-txt-lg-body dark:text-txt-dk-body w-full">
                <tbody>
                    {/* Tenant Details */}
                    <tr>
                        <td colSpan={2} className="font-semibold text-center">{name ?? <Skeleton width={100} />}</td>
                    </tr>
                    <tr className="h-2"></tr>
                    <tr>
                        <td colSpan={2} className="text-center">{address ?? <Skeleton width={150} />}</td>
                    </tr>
                    <tr>
                    <td colSpan={2} className="text-center">
                        { [city, state, plz].filter(Boolean).join(', ') || <Skeleton width={180} />}
                    </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="text-center">{country ?? <Skeleton width={80} />}</td>
                    </tr>
                    <tr className="h-2"></tr>
                    <tr>
                        <td colSpan={2} className="text-center">VAT ID: {vat_id ?? <Skeleton width={100} />}</td>
                    </tr>
                    <tr className="h-8"></tr>

                    {/* Transaction Details */}
                    <tr>
                        <td className="font-semibold">{t('Transaction')}:</td>
                        <td className="text-right">{transactionId ?? <Skeleton width={100} />}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">{t('Period')}:</td>
                        <td className="text-right">{formattedStartDate && formattedEndDate ? `${formattedStartDate} – ${formattedEndDate}` : <Skeleton width={160} />}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">{t('Energy')}:</td>
                        <td className="text-right">{energy !== undefined ? `${energy} kWh` : <Skeleton width={80} />}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">{t('Time')}:</td>
                        <td className="text-right">{time ?? <Skeleton width={80} />}</td>
                    </tr>

                    {/* User or Token */}
                    {user ? (
                        <tr>
                            <td className="font-semibold">{t('User ID')}:</td>
                            <td className="text-right">{user ?? <Skeleton width={80} />}</td>
                        </tr>
                    ) : token ? (
                        <tr>
                            <td className="font-semibold">{t('Token')}:</td>
                            <td className="text-right">{token ?? <Skeleton width={80} />}</td>
                        </tr>
                    ) : null}
                    <tr>
                        <td className="font-semibold">{t('Authorization')}:</td>
                        <td className="text-right">{authorization ?? <Skeleton width={80} />}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">{t('Start Fee')}:</td>
                        <td className="text-right">{start_fee !== null && start_fee !==undefined ? `${start_fee.toFixed(2)} € / session` : `0 € / session`}</td>
                    </tr>                    
                    <tr className="h-2"></tr>

                    {/* Pricing */}
                    <tr>
                        <td className="font-semibold">{t('Tariff')}:</td>
                        <td className="text-right">{tariff !== null && tariff !==undefined ? `${tariff.toFixed(2)} € / kWh` : <Skeleton width={100} />}</td>
                    </tr>
                    <tr className="h-4"></tr>
                    <tr>
                        <td className="font-semibold">{t('Session Total')}:</td>
                        <td className="text-right">{(net !== null && net !==undefined) ? `${net.toFixed(2)} €` : <Skeleton width={100} />}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">{t('VAT')} ({vatRate ?? 0}%):</td>
                        <td className="text-right">{vat !== null && vat !==undefined ? `${vat.toFixed(2)} €` : <Skeleton width={80} />}</td>
                    </tr>
                    <tr className="h-2"></tr>
                    <tr>
                        <td className="font-bold">{t('Total')}:</td>
                        <td className="font-semibold text-right">{totalCost !== null && totalCost !==undefined ? `${totalCost.toFixed(2)} €` : <Skeleton width={100} />}</td>
                    </tr>
                </tbody>
            </table>
        </dl>
    );
};

export default Receipt;
