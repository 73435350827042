import { useState, useEffect, useRef } from 'react';
import { getOngoingSessions } from '../services/apiService';
import { Components } from '../api/client';

type Session = Components.Schemas.Session;
type SummaryStats = Components.Schemas.SummaryStats;
type ResponseOngoingSessionList = Components.Schemas.ResponseOngoingSessionList;

const useOngoingSessions = () => {
    const [sessions, setSessions] = useState<Session[]>([]);
    const [count, setCount] = useState<number | string>('');
    const [summaryStat, setSummaryStat] = useState<SummaryStats>();
    const [nextPage, setNextPage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchSessions = async (nextPageUrl?: string) => {
        try {
            setLoading(true);
            const data: ResponseOngoingSessionList = await getOngoingSessions(nextPageUrl);
            console.log('ONGOING', data)
            setSessions((prev) => {
                const combined = [...prev, ...data.ongoing_sessions.results];
                const uniqueSessions = Array.from(
                    new Map(combined.map((session) => [session.uuid, session])).values()
                );
                return uniqueSessions;
            });
            setNextPage(data.ongoing_sessions.next);
            setCount(data.ongoing_sessions.count);
            setSummaryStat(data.summary_stats);
        } catch (err) {
            console.error(err);
            setError('Failed to fetch ongoing sessions.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSessions(); // Initial fetch
    }, []);

    const nextPageRef = useRef(nextPage);

    useEffect(() => {
        nextPageRef.current = nextPage; // Sync ref with state
    }, [nextPage]);

    const fetchNextOngoingPage = async () => {
        if (nextPageRef.current) {
            await fetchSessions(nextPageRef.current);
        }
    };

    return { sessions, loading, error, nextPage, count, summaryStat, fetchNextOngoingPage };
};

export default useOngoingSessions;
