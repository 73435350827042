import { useState, useEffect, useRef } from "react";
import { getCardsList } from "../services/apiService";
import { Components } from "../api/client";

type Card = Components.Schemas.CardFlattened;
type ResponseCardList = Components.Schemas.ResponseCardList;

const useCardsList = () => {
    const [cards, setCards] = useState<Card[]>([]);
    const [nextPage, setNextPage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchCards = async (nextPageUrl?: string) => {
        try {
            setLoading(true);
            const data: ResponseCardList = await getCardsList(nextPageUrl);
            setCards((prev) => {
                const combined = [...prev, ...data.cards.results];
                const uniqueCards = Array.from(
                    new Map(combined.map((card) => [card.uuid, card])).values()
                );
                return uniqueCards;
            });
            setNextPage(data.cards.next);
        } catch (err) {
            console.error(err);
            setError('Failed to fetch the yearly sessions.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCards(); // Initial fetch
    }, []);

    const nextPageRef = useRef(nextPage);

    useEffect(() => {
        nextPageRef.current = nextPage; // Sync ref with state
    }, [nextPage]);

    const fetchNextCardPage = async () => {
        if (nextPageRef.current) {
            await fetchCards(nextPageRef.current);
        }
    };

    return { cards, loading, error, nextPage, fetchNextCardPage };
};

export default useCardsList;