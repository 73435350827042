import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useUserList from '../hooks/useUserlist';
import { filterData } from '../utils/filterUtils';
import { useNavigate } from 'react-router-dom';

// components
import Heading from '../components/typography/Heading';
import Table, { TableColumn, TableRow } from '../components/basics/table';
import SearchInput from '../components/SearchInput';
import ErrorPage from './ErrorPage';

const UsersPage: React.FC = () => {
  const { users, error, loading, nextPage, fetchNextUserPage } = useUserList();
  const [loadingMore, setLoadingMore] = useState(false);
  const [offsetTop, setOffsetTop] = useState(0);
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState<TableRow[]>([]);

  const userColumns: TableColumn[] = [
    { header: t(""), key: "row_number" },
    { header: t("email"), key: "email" },
    { header: t("name"), key: "name" },
    // { header: t("tenant"), key: "tenant" },
    { header: t("company"), key: "company" },
    { header: t("priority"), key: "prio" },
  ];

  useEffect(() => {
    const calculateOffsetTop = () => {
        const navbarHeight = 64;
        const gapSize = 8;
        const additionalOffset = 0;
        setOffsetTop(navbarHeight + gapSize * 2 + additionalOffset + 150);
    };

    calculateOffsetTop();
    window.addEventListener('resize', calculateOffsetTop);

    return () => {
        window.removeEventListener('resize', calculateOffsetTop);
    };
}, []);

  useEffect(() => {
    // search based on all criterials in the table
    const criteria = { email: searchQuery, name: searchQuery, tenant: searchQuery, prio: searchQuery, company: searchQuery };
    const mappedData = users.map((user, index) => ({
        row_number: index + 1,
        uuid: user.uuid as string,
        email: user.email,
        name: user.name || 'N/A',
        // tenant: user.tenant?.name || 'N/A',
        company: user?.company || 'N/A',
        prio: user.prio ? "Yes" : "No",
      }));
    setFilteredData(filterData(mappedData, criteria));
  }, [users, searchQuery]);

  if (error) {
    return <ErrorPage errorCode={500} errorMessage={error} />;
  }

  const handleFetchNextPage = async () => {
      if (loadingMore || loading || !nextPage) return;
      setLoadingMore(true);
      await fetchNextUserPage();
      setLoadingMore(false);
    };

  return (
    <div>
      <Heading
        title={t('Users')}
        highlight={t('Your')}
        description={t('All the Users')} //What is this!!
      />
      <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body flex flex-col items-center">
        <div className="w-full flex justify-center mb-6">
          <SearchInput
            id="search"
            value={searchQuery}
            onChange={setSearchQuery} // Directly set the search query
            displayKey="name" // Specify the key to display (this is kept for clarity, although it's not used anymore without suggestions)
          />
        </div>
        <div className="w-full flex-1">
          <div className="table-container w-full">
            <Table 
              table_name="users" 
              columns={userColumns} 
              data={filteredData} 
              onRowClick={(row) => navigate(`/users/${row.uuid}`)}
              loading={loading && filteredData.length === 0}
              loadingMore={loadingMore}
              offsetTop={offsetTop}
              isScrollable={true}
              onLoadMore={handleFetchNextPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersPage;
