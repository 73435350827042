import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './routes/routes';

const App: React.FC = () => {

    console.log(process.env.REACT_APP_WS_URL)

    return (
        <div className="h-full bg-bg-lg-bg dark:bg-bg-dk-bg">
            <RouterProvider router={router} />
        </div>
    );
};

export default App;